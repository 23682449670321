import React from 'react';
import { renderRoutes } from 'react-router-config';
import styled from 'styled-components';
import { Icon, NavBar } from 'antd-mobile';

import IconFont from '../components/IconFont';

const RightButton = styled.span`
  height: 24px;
  line-height: 24px;
  text-align: center;
  border: 1px solid #fff;
  padding: 2px 8px;
  border-radius: 10px;
`;

const excludePath = ['/contract/list', '/booth/list', '/activity/list'];

const renderRightContent = history => {
  const path = document.location.hash.slice(1);
  const isListPage = path.indexOf('/list') > -1;
  if (!isListPage) return null;
  const willPass = excludePath.some(item => path.indexOf(item) > -1);
  if (willPass) return null;
  return (
    <RightButton
      onClick={() => {
        history.push(path.replace('/list', '/add'));
      }}
    >
      <span>新增&nbsp;</span>
      <IconFont color="#fff" fontSize={16} value="&#xe711;" />
    </RightButton>
  );
};

const MainLayout = ({ route, history }) => {
  const path = document.location.hash.slice(1);
  const current = route.routes.find(r => r.path === path);
  const navName = current ? current.name : '';

  return (
    <div>
      <NavBar
        mode="dark"
        leftContent="返回"
        onLeftClick={() => history.goBack()}
        rightContent={renderRightContent(history)}
        icon={path !== '/' ? <Icon type="left" /> : null}
      >
        {navName}
      </NavBar>
      {renderRoutes(route.routes)}
    </div>
  );
};

export default React.memo(MainLayout);
