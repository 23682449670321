import React from 'react';
import { renderRoutes } from 'react-router-config';

const SignLayout = ({ route }) => {
  return <div>{renderRoutes(route.routes)}</div>;
};

export default React.memo(SignLayout);


// import React from "react";
// import { renderRoutes } from "react-router-config";
// import styled from "styled-components";
// import { Icon } from "antd-mobile";

// const Container = styled.div`
//   box-sizing: border-box;
//   height: 100vh;
//   padding-top: 51px;

//   .header {
//     box-sizing: border-box;
//     position: fixed;
//     height: 50px;
//     width: 100%;
//     left: 0;
//     top: 0;
//     background-color: #ddd;
//     color: #101010;
//     text-align: center;
//     font-size: 20px;
//     line-height: 50px;

//     .icon-close {
//       position: absolute;
//       left: 0;
//       padding: 8px 8px;
//     }
//   }
// `;

// const BasicLayout = ({ route }) => {
//   const path = document.location.pathname;
//   const current = route.routes.find(r => r.path === path);
//   const navName = current ? current.name : "";

//   return (
//     <Container>
//       <header className="header">
//         <Icon onClick={() => {}} className="icon-close" size="lg" type="cross" />
//         {navName}
//       </header>
//       {renderRoutes(route.routes)}
//     </Container>
//   );
// };

// export default React.memo(BasicLayout);
