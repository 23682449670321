import React, { useState, useCallback, useEffect } from "react";
import { createForm } from "rc-form";
import styled from "styled-components";
import { Icon, List, InputItem, Picker, DatePicker, Button } from "antd-mobile";
import { CSSTransition } from "react-transition-group";

const Container = styled.div`
  height: 100vh;
  background-color: #fff;
  padding-top: 51px;

  .header {
    box-sizing: border-box;
    position: fixed;
    height: 50px;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ddd;
    color: #101010;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    .icon-close {
      position: absolute;
      left: 0;
      padding: 8px 8px;
    }
  }

  input[type="text"] {
    text-align: right;
  }

  .action-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &.fly-enter,
  &.fly-appear {
    transform: rotateZ(30deg) translate3d(100%, 0, 0);
  }
  &.fly-enter-active,
  &.fly-appear-active {
    transition: transform 0.35s;
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  /* &.fly-exit {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  &.fly-exit-active {
    transition: transform 0.35s;
    transform: rotateZ(30deg) translate3d(100%, 0, 0);
  } */

  /* 修复苹果X，因屏幕宽度文案缩略问题 */
  .am-list-item .am-list-line .am-list-extra {
    flex-basis: 42%;
  }

  .am-list-item .am-input-item .am-list-item-middle {
    border-bottom: 5px solid #ddd;
  }
`;

const shopTypes = [
  {
    label: "业态类型1",
    value: "t1"
  },
  {
    label: "业态类型2",
    value: "t2"
  },
  {
    label: "业态类型3",
    value: "t3"
  },
  {
    label: "业态类型4",
    value: "t4"
  }
];

const sellTypes = [
  {
    label: "经营性质1",
    value: "t1"
  },
  {
    label: "经营性质2",
    value: "t2"
  },
  {
    label: "经营性质3",
    value: "t3"
  },
  {
    label: "经营性质4",
    value: "t4"
  }
];

const nowTimeStamp = Date.now();
const now = new Date(nowTimeStamp);

const Booking = ({ history, form, id = 412, name = "7幢4楼36E" }) => {
  const { getFieldProps, setFieldsValue } = form;
  const [showStatus, setShowStatus] = useState(true);

  const handleBack = useCallback(() => {
    setShowStatus(false);
  }, []);

  const handleReset = useCallback(() => {
    form.resetFields();
    setFieldsValue({ selectedShop: name, schedule: now });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    setFieldsValue({ selectedShop: name, schedule: now });
  }, [setFieldsValue, name]);

  const handleSubmit = useCallback(() => {
    form.validateFields((error, value) => {
      if (error) return;
      value.id = id;
      console.log(value);
    });
  }, [id, form]);

  return (
    <CSSTransition
      in={showStatus}
      timeout={300}
      classNames="fly"
      appear={true}
      unmountOnExit
      onExited={history.goBack}
    >
      <Container>
        <div className="header">
          <Icon onClick={handleBack} className="icon-close" size="lg" type="cross" />
          预 约
        </div>
        <List>
          <InputItem {...getFieldProps("selectedShop")} editable={false}>
            已选地址
          </InputItem>
          <Picker data={shopTypes} cols={1} {...getFieldProps("shopType")}>
            <List.Item arrow="horizontal">业态类型</List.Item>
          </Picker>
          <Picker data={sellTypes} cols={1} {...getFieldProps("sellType")}>
            <List.Item arrow="horizontal">经营性质</List.Item>
          </Picker>
          <InputItem clear {...getFieldProps("shopName")}>
            品牌名称
          </InputItem>
          <InputItem clear {...getFieldProps("shopDesc")}>
            品牌介绍
          </InputItem>
          <InputItem clear {...getFieldProps("contacts")}>
            联系人
          </InputItem>
          <InputItem clear {...getFieldProps("phone")}>
            联系方式
          </InputItem>
          <DatePicker
            {...getFieldProps("schedule", {
              rules: [{ required: true, message: "请选择一个时间" }]
            })}
          >
            <List.Item arrow="horizontal">预约时间</List.Item>
          </DatePicker>
          <List.Item>
            <div className="action-box">
              <Button
                onClick={handleReset}
                size="small"
                inline
                style={{ marginRight: 20, width: 120, textAlign: "center" }}
              >
                重置
              </Button>
              <Button onClick={handleSubmit} type="primary" size="small" style={{ width: 120 }} inline>
                提交
              </Button>
            </div>
          </List.Item>
        </List>
      </Container>
    </CSSTransition>
  );
};

export default createForm()(Booking);
