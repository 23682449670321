import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  height: 100vh;
  padding-top: 51px;

  padding: 20px 15px;
  background-color: #fff;

  .header {
    box-sizing: border-box;
    position: fixed;
    height: 50px;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ddd;
    color: #101010;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    .icon-close {
      position: absolute;
      left: 0;
      padding: 8px 8px;
    }
  }

  .footer {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 120px;

    i {
      position: absolute;
      top: 52%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      color: #a9a9a9;

      .desc {
        padding: 0 15px;
      }

      .line {
        height: 1px;
        width: 26%;
        background-color: #a9a9a9;
      }
    }
  }

  .tips {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
    font-size: 14px;
    color: #a9a9a9;
  }

  .input-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    input {
      box-sizing: border-box;
      height: 50px;
      width: 100%;
      background-color: #eee;
      border-style: none;
      padding: 15px;
      margin-bottom: 15px;
    }

    .inner-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin-top: -15px;
        width: 35%;
      }
    }

    .input-password {
      width: 60%;
    }
  }

  &.fly-enter,
  &.fly-appear {
    transform: rotateZ(30deg) translate3d(100%, 0, 0);
  }
  &.fly-enter-active,
  &.fly-appear-active {
    transition: transform 0.35s;
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  &.fly-exit {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  &.fly-exit-active {
    transition: transform 0.35s;
    transform: rotateZ(30deg) translate3d(100%, 0, 0);
  }

  @media only screen and (max-height: 600px) {
    .footer {
      /* 120px = Footer 组件自身高度 */
      bottom: -120px;
    }
  }
`;
