import axios from "axios";

let baseUrl;

try {
  baseUrl = process.env.REACT_APP_BaseUrl;
} catch (error) {
  console.error(error);
  throw new Error(error);
}

const axiosInstance = axios.create({
  baseURL: baseUrl
});

// const PathsWithoutAuth = ["/sign"];

axiosInstance.interceptors.request.use(config => {
  try {
    const tokenObj = JSON.parse(localStorage.getItem("userToken") || '{}');
    if (!tokenObj ||!tokenObj.token) {
      return (window.location.hash = '#/user/login');
    };
    config.headers["Authorization"] = "Bearer " + tokenObj.token;
  } catch (error) {
    console.error(error);
  } finally {
    return config;
  }
});

axiosInstance.interceptors.response.use(
  res => {
    const { code, message } = res.data;
    if (code === 500 && (message === 'token过期' || message === 'token验证失败')) {
      localStorage.setItem('userToken', '');
      window.location.hash = '#/user/login';
    }

    return res.data;
  },
  err => {
    console.log(err, "网络错误");
  }
);

export { baseUrl, axiosInstance };
