import { fromJS } from 'immutable';

const defaultState = fromJS({
  baseImgUrl: process.env.REACT_APP_BaseImgUrl,
  brandTypeDict: ['零售', '餐饮', '服饰', '数码产品', '轻餐', '教育培训', '休闲娱乐', '美容美发'],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
