import { fromJS } from 'immutable';

import * as actionTypes from './constants';

const getLocalData = item => {
  try {
    let data = localStorage.getItem(item);
    if (data) {
      data = JSON.parse(data);
      return data || {};
    } else {
      return {};
    }
  } catch (error) {
    console.error(error);
    return {};
  }
};

//* 检查token时间是否失效，没失效就设置登录状态为true
const checkExp = () => {
  // const tokenObj = getLocalData('userToken');
  const tokenObj = getLocalData('userToken');
  if (tokenObj && tokenObj.exp) {
    return tokenObj.exp > Date.now();
  }
  return false;
};

const defaultState = fromJS({
  userInfo: getLocalData("userInfo"),
  userToken: getLocalData("userToken").token,
  sentStatus: false,
  loginStatus: checkExp(),
  toastMessage: "",
  isToastVisible: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_USER_INFO:
      return state.set('userInfo', action.data);
    case actionTypes.CHANGE_USER_TOKEN:
      return state.set('userToken', action.data);
    case actionTypes.CHANGE_SENT_STATUS:
      return state.set('sentStatus', action.data);
    case actionTypes.CHANGE_LOGIN_STATUS:
      return state.set('loginStatus', action.data);
    case actionTypes.CHANGE_TOAST_MESSAGE:
      return state.set('toastMessage', action.data);
    case actionTypes.TOGGLE_IS_TOAST_VISIBLE:
      return state.set('isToastVisible', !state.get('isToastVisible'));
    default:
      return state;
  }
};
