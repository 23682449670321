import React, { lazy, Suspense } from "react";
import { Redirect } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import BasicLayout from "../layouts/BasicLayout";
import BlankLayout from "../layouts/BlankLayout";

import LoginComponent from "../applications/User/Login"
import VisitMapComponent from "../applications/Visit/Map"
import VisitBookingComponent from "../applications/Visit/Booking"

const SuspenseComponent = Component => props => {
  return (
    <Suspense fallback={null}>
      <Component {...props}></Component>
    </Suspense>
  );
};

const NotFoundComponent = lazy(() => import("../components/404"));
const HomeComponent = lazy(() => import("../applications/Home"));
const SuggestionComponent = lazy(() => import("../applications/Suggestion"));
const RepairsListComponent = lazy(() => import("../applications/Repairs/List"));
const RepairsAddComponent = lazy(() => import("../applications/Repairs/Add"));
const ReportListComponent = lazy(() => import("../applications/Report/List"));
const ReportAddComponent = lazy(() => import("../applications/Report/Add"))

const ActivityListComponent = lazy(() =>
  import("../applications/Activity/List")
);
const ActivityAddComponent = lazy(() => import("../applications/Activity/Add"));
const ActivityDetailComponent = lazy(() => import("../applications/Activity/Detail"));
const ShopComponent = lazy(() => import("../applications/Shop"));
const ContarctListComponent = lazy(() =>
  import("../applications/Contract/List")
);
const ContarctDetailComponent = lazy(() =>
  import("../applications/Contract/Detail")
);
const WelcomeComponent = lazy(() => import("../applications/User/Welcome"));
const UserCenterComponent = lazy(() => import("../applications/User/Center"));
const UserHelpComponent = lazy(() => import("../applications/User/Help"));
const UserMessageComponent = lazy(() => import("../applications/User/Message"));
const PayComponent = lazy(() => import("../applications/Pay"));
const PayRecordComponent = lazy(() => import("../applications/Pay/Record"));
const PayPendingComponent = lazy(() => import("../applications/Pay/Pending"));
const VisitListComponent = lazy(() => import("../applications/Visit/List"));
const AdvertiseComponent = lazy(() => import("../applications/Advertise"));

const BoothListComponent = lazy(() => import("../applications/Booth/List"));
const BoothAddComponent = lazy(() => import("../applications/Booth/Add"));

const ParkingComponent = lazy(() => import("../applications/User/Parking"));
const DecorationAddComponent = lazy(() => import("../applications/Decoration/Add"));
const DecorationListComponent = lazy(() => import("../applications/Decoration/List"));

// const VisitBookingComponent = lazy(() => import("../applications/Visit/Booking"));
// const LoginComponent = lazy(() => import("../applications/User/Login"));
// const VisitMapComponent = lazy(() => import("../applications/Visit/Map"));


export default [
  {
    component: BlankLayout,
    routes: [
      {
        path: "/user",
        component: BasicLayout,
        routes: [
          {
            path: "/user",
            exact: true,
            render: () => <Redirect to={"/user/welcome"} />
          },
          {
            path: "/user/welcome",
            exact: true,
            component: SuspenseComponent(WelcomeComponent)
          },
          {
            path: "/user/login",
            exact: true,
            component: SuspenseComponent(LoginComponent)
          }
        ]
      },
      {
        path: "/visit",
        component: BasicLayout,
        routes: [
          {
            path: "/visit/list",
            exact: true,
            name: "选址预约",
            component: SuspenseComponent(VisitListComponent)
          },
          {
            path: "/visit/booking/:id",
            exact: true,
            name: "查看地图",
            component: SuspenseComponent(VisitBookingComponent)
          },
          {
            path: "/visit/map",
            exact: true,
            name: "地图选址",
            component: SuspenseComponent(VisitMapComponent)
          },
          {
            component: SuspenseComponent(NotFoundComponent)
          }
        ]
      },
      {
        path: "/",
        component: MainLayout,
        routes: [
          {
            path: "/",
            exact: true,
            name: "首页",
            render: () => <Redirect to={"/user/welcome"} />
          },
          {
            path: "/home",
            name: "首页",
            component: SuspenseComponent(HomeComponent)
          },
          {
            path: "/suggesion",
            name: "意见投诉",
            component: SuspenseComponent(SuggestionComponent)
          },
          {
            path: "/repairs/list",
            name: "报修",
            component: SuspenseComponent(RepairsListComponent)
          },
          {
            path: "/repairs/add",
            name: "新增报修",
            component: SuspenseComponent(RepairsAddComponent)
          },
          {
            path: "/report/list",
            name: "报事",
            component: SuspenseComponent(ReportListComponent)
          },
          {
            path: "/report/add",
            name: "新增报事",
            component: SuspenseComponent(ReportAddComponent)
          },
          {
            path: "/activity/list",
            name: "活动",
            component: SuspenseComponent(ActivityListComponent)
          },
          {
            path: "/activity/detail/:id",
            name: "活动详情",
            component: SuspenseComponent(ActivityDetailComponent)
          },
          {
            path: "/decoration/add",
            name: "申请装修",
            component: SuspenseComponent(DecorationAddComponent)
          },
          {
            path: "/decoration/my",
            name: "我的申请",
            component: SuspenseComponent(DecorationListComponent)
          },
          {
            path: "/activity/add",
            name: "申请活动",
            component: SuspenseComponent(ActivityAddComponent)
          },
          {
            path: "/booth/list",
            name: "外摆列表",
            component: SuspenseComponent(BoothListComponent)
          },
          {
            path: "/booth/add/:id",
            name: "外摆申请",
            component: SuspenseComponent(BoothAddComponent)
          },
          {
            path: "/myshop",
            name: "我的店铺",
            component: SuspenseComponent(ShopComponent)
          },
          {
            path: "/contract/list",
            name: "合同",
            component: SuspenseComponent(ContarctListComponent)
          },
          {
            path: "/contract/detail/:id",
            name: "合同详情",
            exact: true,
            component: SuspenseComponent(ContarctDetailComponent)
          },
          {
            path: "/pay",
            name: "缴费",
            exact: true,
            component: SuspenseComponent(PayComponent)
          },
          {
            path: "/parking",
            name: "停车券",
            exact: true,
            component: SuspenseComponent(ParkingComponent)
          },
          {
            path: "/center",
            name: "个人中心",
            component: SuspenseComponent(UserCenterComponent)
          },
          {
            path: "/help",
            name: "帮助中心",
            component: SuspenseComponent(UserHelpComponent)
          },
          {
            path: "/message",
            name: "消息通知",
            component: SuspenseComponent(UserMessageComponent)
          },
          {
            path: "/advertise",
            name: "广告申请",
            exact: true,
            component: SuspenseComponent(AdvertiseComponent)
          },
          {
            path: "/pay/record",
            name: "缴费记录",
            exact: true,
            component: SuspenseComponent(PayRecordComponent)
          },
          {
            path: "/pay/pending",
            name: "待交费用",
            exact: true,
            component: SuspenseComponent(PayPendingComponent)
          },

          {
            component: SuspenseComponent(NotFoundComponent)
          }
        ]
      },
      {
        component: SuspenseComponent(NotFoundComponent)
      }
    ]
  }
];
