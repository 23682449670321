import React, { useState, useEffect, useCallback } from "react";
import { RCMap, TileLayer, Polygon, CircleMarker } from "rc-leaflet";
import { CSSTransition } from "react-transition-group";
import { Icon, Button } from "antd-mobile";

import { Container } from "./style";

const data = require("./floorInfo");

const initialState = {
  center: [0, 0],
  vectors: data,
  zoom: 0,
  curtVector: null
};

const Map = ({ history }) => {
  const [showStatus, setShowStatus] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [mapState, setMapState] = useState(initialState);
  //TODO: 解析queryString，vectors聚焦

  function handleVectorClick({
    target: {
      options: { shopNumber }
    }
  }) {
    let curt;
    const newState = mapState.vectors.map(v => {
      const tmp = { ...v };
      if (v.number === shopNumber) {
        curt = v;
        tmp.color = "red";
      } else {
        tmp.color = "rgba(0,0,0,0)";
      }
      return tmp;
    });
    setMapState(previousState => ({
      ...previousState,
      vectors: newState,
      // center: curt.center,
      curtVector: curt
    }));
  }

  function renderVectors(data) {
    const ary = [];
    data.forEach(({ name, number, polygons, center, ...rest }) => {
      ary.push(
        <Polygon
          key={`P${number}`}
          points={polygons}
          onClick={handleVectorClick}
          shopNumber={number}
          // tap={false}
          fillOpacity={0.3}
          {...rest}
        />,
        <CircleMarker key={`M${number}`} center={center} radius={2} />
      );
    });
    return ary;
  }

  const handleBack = useCallback(() => {
    setShowStatus(false);
  }, []);

  const handleAction = useCallback(() => {
    history.push('/visit/booking')
  }, [history]);

  useEffect(() => {
    if (mapState.curtVector) {
      setShowButton(true);
    }
  }, [mapState.curtVector]);

  return (
    <CSSTransition
      in={showStatus}
      timeout={300}
      classNames="slide"
      appear={true}
      unmountOnExit
      onExited={() => history.push('/visit/list')}
    >
      <Container>
        <RCMap center={mapState.center} zoom={mapState.zoom} minZoom={1} maxZoom={4}>
          <TileLayer
            url={"http://yxcity.jerryshi.com/maps/{z}/{x}/{y}.jpg"}
            noWrap={true}
            bounds={[
              [-90, -180],
              [90, 180]
            ]}
          />
          {mapState.vectors && [...renderVectors(mapState.vectors)]}
        </RCMap>
        <div className="header">
          <Icon onClick={handleBack} className="icon-close" size="lg" type="cross" />预 约
        </div>
        <CSSTransition in={showButton} unmountOnExit classNames="slide-fade-up" timeout={350}>
          <div className="action-box">
            <Button type="primary" onClick={handleAction}>
              预约{mapState.curtVector ? ` - [${mapState.curtVector.name}]` : ""}
            </Button>
          </div>
        </CSSTransition>
      </Container>
    </CSSTransition>
  );
};

export default Map;
