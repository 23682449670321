import { useEffect, useRef } from "react";

export const useInterval = (cb, delay) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = cb;
  });

  useEffect(() => {
    //* 可暂停
    if (delay !== null) {
      const timer = setInterval(() => ref.current(), delay);
      return () => clearInterval(timer);
    }
  }, [delay]);
};
