export const CHANGE_USER_SIGNOUT = "user/login/CHANGE_USER_SIGNOUT";

export const CHANGE_USER_INFO = "user/login/CHANGE_USER_INFO";

export const CHANGE_USER_TOKEN = "user/login/CHANGE_USER_TOKEN";

export const CHANGE_SENT_STATUS = "user/login/CHANGE_SENT_STATUS";

export const CHANGE_LOGIN_STATUS = "user/login/CHANGE_LOGIN_STATUS";

export const CHANGE_TOAST_MESSAGE = "user/login/CHANGE_TOAST_MESSAGE";

export const TOGGLE_IS_TOAST_VISIBLE = "user/login/TOGGLE_IS_TOAST_VISIBLE";

export const TOGGLE_IS_BIND_PHONE_VISIBLE = "user/login/TOGGLE_IS_BIND_PHONE_VISIBLE"
