import { createGlobalStyle } from 'styled-components';

// TODO: 项目稳定了再使用本地资源
export const IconStyle = createGlobalStyle`
@font-face {
  font-family: 'iconfont';  /* project id 1492951 */
  src: url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.eot');
  src: url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.woff') format('woff'),
  url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_1492951_5gq86ct9jcj.svg#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;
