import { axiosInstance } from './config';

export const loginByVcodeRequest = (mobile, captcha) => {
  return axiosInstance.post(`/gunsApi/login/businessCaptcha`, { mobile, captcha });
};

export const sentVcodeRequest = (mobile, smsType = 'AUTH') => {
  return axiosInstance.post('/gunsApi/smVerify/getSmsVerify', { mobile, smsType });
};

export const postDecoration = (data) => {
  return axiosInstance.post('/businessman/all/addRenovation', data);
};

export const getDecorations = () => {
  return axiosInstance.get('/businessman/all/myRenovation');
};

export const submitSuggestion = data => {
  return axiosInstance.post(`/businessman/all/addComplaint`, data);
};

export const submitAdvertise = data => {
  return axiosInstance.post(`/businessman/all/addAdvertise`, data);
};

export const fetchRepairs = data => {
  return axiosInstance.get(`/businessman/all/queryRepairByPhone`, { params: { ...data, pageSize: 1000 } });
};

export const submitRepair = data => {
  return axiosInstance.post(`/businessman/all/addRepair`, data);
};

export const fetchReport = data => {
  return axiosInstance.get(`/businessman/all/queryReportByPhone`, { params: { ...data, pageSize: 1000 } });
};

export const submitReport = data => {
  return axiosInstance.post(`/businessman/all/addReport`, data);
};

export const fetchMyShop = () => {
  return axiosInstance.get(`/businessman/all/queryByPhone`);
};

export const fetchBooth = () => {
  return axiosInstance.get(`/businessman/all/queryByPositionType`, { params: { positionType: 1 } });
};

export const fetchBoothById = (id) => {
  return axiosInstance.get(`/businessman/all/queryByPositionId`, { params: { id } });
};

export const fetchADById = (id) => {
  return axiosInstance.get(`/businessman/all/queryByPositionId`, { params: { id } });
};

export const fetchAds = () => {
  return axiosInstance.get(`/businessman/all/queryByPositionType`, { params: { positionType: 0 } });
};

export const fetchSystemMessages = (pageNo, pageSize) => {
  return axiosInstance.get(`businessman/all/getMyAnnouncementSend`, {
    params: { msgCategory: 2, pageNo, pageSize },
  });
};

export const fetchNotices = (pageNo, pageSize) => {
  return axiosInstance.get(`businessman/all/getMyAnnouncementSend`, {
    params: { msgCategory: 1, pageNo, pageSize },
  });
};

export const postMessageRead = anntId => {
  return axiosInstance.post(`/businessman/all/editByAnntId`, {  anntId });
};

export const postActivity = data => {
  return axiosInstance.post(`/businessman/all/addActivity`, data);
}; //

export const postBooth = data => {
  return axiosInstance.post(`/businessman/all/addExternal`, data);
};

export const fetchVisitData = () => {
  return axiosInstance.get("/businessman/all/mapList");
}

export const fetchMyContract = () => {
  return axiosInstance.get("/businessman/all/queryMyContract");
}

export const fetchZSpay = () => {
  return axiosInstance.get("/businessman/all/listZsPay?pageSize=50");
}

export const fetchWYpay = () => {
  return axiosInstance.get("/businessman/all/list?pageSize=50");
}

// --------------

export const uploadFile = file => {
  const param = new FormData();
  param.append('file', file);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return axiosInstance.post(`/sys/common/upload`, param, config);
};
// /jeecg-boot/businessman/all/applyActivity
export const fetchActivities = () => {
  return axiosInstance.get(`/businessman/all/listActivity`);
};

export const getActivityDetail = (id) => {
  return axiosInstance.get(`/businessman/all/getByActiId`, { params: { id } });
};

export const fetchBrands = (pageNo = 1, building, brandType, order) => {
  return axiosInstance.get('/userMobile/all/queryByFloorAndType', {
    params: { pageNo, pageSize: 10, building, brandType, order },
  });
}; //

export const fetchBrandsByName = search => {
  return axiosInstance.get('/userMobile/all/findBrand', {
    params: { search },
  });
};

// --------

export const fetchBrandDetail = (id, userId) => {
  return axiosInstance.get('/userMobile/all/queryByBrandId', {
    params: { id, userId },
  });
};

export const brandLike = id => {
  return axiosInstance.get('/userMobile/all/good', { params: { id } });
};

export const brandCollection = id => {
  return axiosInstance.get('/userMobile/all/collection', {
    params: { id },
  });
};

export const fetchHomeData = () => {
  return axiosInstance.get('/userMobile/all/listWheel');
};

export const fetchProvince = () => {
  return axiosInstance.get('/gunsApi/login/area');
};

export const signup = data => {
  return axiosInstance.post(`/gunsApi/login/userRegister`, data);
};

export const fetchMapByName = search => {
  return axiosInstance.get('/userMobile/all/queryMapByString', { params: { search } });
};

export const vaildLuckyCode = luckKey => {
  return axiosInstance.get('/userMobile/all/addLuck', { params: { luckKey } });
}; // /userMobile/all/listCoupon

export const fetchCoupons = () => {
  return axiosInstance.get('/userMobile/all/listCoupon');
};

export const saveCard = coupId => {
  return axiosInstance.get('/userMobile/all/saveCard', { params: { coupId } });
}; //

export const checkoutCard = id => {
  return axiosInstance.get('/userMobile/all/delCoupon', { params: { id } });
};

export const checkoutSubscribe = code => {
  return axiosInstance.get('/gunsApi/login/subscribe', { params: { code } });
};

export const wxLogin = code => {
  return axiosInstance.get('/gunsApi/login/wxUserInfo', { params: { code } });
};

export const bindPhoneRequest = data => {
  return axiosInstance.post('/gunsApi/login/wxAddPhone', data);
};

export const addParkingCoupon = timeKey => {
  return axiosInstance.get('/userMobile/all/giveCarCoupon', { params: { timeKey } });
};

export const getBrandCommentById = id => {
  return axiosInstance.get('/userMobile/wc/listComment', { params: { id } });
};

export const postBrandComment = data => {
  return axiosInstance.post('/userMobile/all/comment', data);
};

export const getCarCoupon = (data) => {
  return axiosInstance.get('/businessman/all/carCoupon', data);
};
