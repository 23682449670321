const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    if(timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
      clearTimeout(timer);
    }, delay);
  };
};

const getExpiredTimestamp = hours => {
  const HourOfMsec = 1000 * 60 * 60;
  return Date.now() + HourOfMsec * hours - 60 * 1000;
};

export { debounce, getExpiredTimestamp };
