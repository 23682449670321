import styled from "styled-components";
import bg from "../../../assets/images/map_backgound.png";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;

  .header {
    z-index: 2000;
    box-sizing: border-box;
    position: fixed;
    height: 50px;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ddd;
    color: #101010;
    text-align: center;
    font-size: 20px;
    line-height: 50px;

    .icon-close {
      position: absolute;
      left: 0;
      padding: 8px 8px;
    }
  }

  .leaflet-container {
    background: url(${bg}) repeat;
    background-size: 50px auto;
  }

  &.slide-enter,
  &.slide-appear {
    transform: translate3d(100%, 0, 0);
  }
  &.slide-enter-active,
  &.slide-appear-active {
    transition: transform 0.3s;
    transform: translate3d(0, 0, 0);
  }
  /* &.slide-exit {
    transform: translate3d(0, 0, 0);
  }
  &.slide-exit-active {
    transition: transform 0.3s;
    transform: translate3d(100%, 0, 0);
  } */

   /* -------------------------- */
  .slide-fade-up-enter {
    transform: translateY(15px);
    pointer-events: none;
    opacity: 0;
  }

  .slide-fade-up-enter-active {
    transition: all 0.35s ease-in-out;
    transition-delay: 0.1s;
    transform: translateY(0px);
    position: relative;
    opacity: 1;
  }

  .slide-fade-up-enter-done {
    opacity: 1;
  }

  .slide-fade-up-exit {
    transform: translateY(0px);
    pointer-events: none;
    opacity: 1;
  }

  .slide-fade-up-exit-active {
    transition: all 250ms ease-in-out;
    transform: translateY(-15px);
    position: relative;
    opacity: 0;
  }

  .slide-fade-up-exit-done {
    opacity: 0;
  }
  /* -------------------------- */

  .action-box {
    z-index: 2000;
    position: fixed;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;

    .am-button {
      width: 60%;
    }
  }
`;
