import {
  // loginByPhoneRequest,
  sentVcodeRequest,
  loginByVcodeRequest
} from "../../../api/request";
import { fromJS } from "immutable";
import {
  CHANGE_USER_INFO,
  CHANGE_SENT_STATUS,
  CHANGE_LOGIN_STATUS,
  CHANGE_USER_TOKEN,
  CHANGE_TOAST_MESSAGE,
  TOGGLE_IS_TOAST_VISIBLE
} from "./constants";

import { getExpiredTimestamp } from "../../../utils/fp";

const HoursOfTokenExpired = parseInt(process.env.REACT_APP_HoursOfTokenExpired, 10);

export const saveUserInfo = data => ({
  type: CHANGE_USER_INFO,
  data
});

export const saveUserToken = data => ({
  type: CHANGE_USER_TOKEN,
  data
});

export const saveSentStatus = data => ({
  type: CHANGE_SENT_STATUS,
  data
});

export const saveLoginStatus = data => ({
  type: CHANGE_LOGIN_STATUS,
  data
});

export const saveToastMessage = data => ({
  type: CHANGE_TOAST_MESSAGE,
  data
});

export const toggleIsToastVisible = () => ({
  type: TOGGLE_IS_TOAST_VISIBLE
});

const saveLoginData = (dispatch, data) => {
  const { token } = data;
  delete data.token;
  dispatch(saveUserToken(token));
  dispatch(saveLoginStatus(true));
  dispatch(saveUserInfo(fromJS(data)));
  const exp = getExpiredTimestamp(HoursOfTokenExpired);
  localStorage.setItem("userInfo", JSON.stringify(data, null, 2));
  localStorage.setItem("userToken", JSON.stringify({ token, exp }, null, 2));
};

export const signgout = () => {
  return dispatch => {
    dispatch(saveLoginStatus(false));
    dispatch(saveUserToken(""));
    dispatch(saveUserInfo({}));
    localStorage.setItem("userInfo", '{}');
    localStorage.setItem("userToken", '{}');
  };
};

export const loginByVcode = (phone, captcha) => {
  return dispatch => {
    loginByVcodeRequest(phone, captcha)
      .then(res => {
        if (res.success) {
          saveLoginData(dispatch, res.result);
        } else {
          dispatch(saveToastMessage(res.message || "登录失败"));
          dispatch(toggleIsToastVisible());
        }
      })
      .catch(() => {
        console.log("登录失败！");
      });
  };
};

export const sentVcode = (phone, smsType) => {
  return dispatch => {
    sentVcodeRequest(phone, smsType)
      .then(res => {
        if (res.code === 200) {
          dispatch(saveSentStatus(true));
        }
      })
      .catch(() => {
        console.log("请求失败！");
      });
  };
};
