import React, { useState } from "react";
import styled from "styled-components";
import { useInterval } from "../../utils/hooks";

const Button = styled.button`
  width: 100%;
  height: 50px;
  color: ${props => props.isInitial ? '#fff' : '#108ee9'};
  background-color: ${props => props.isInitial ? '#108ee9' : '#fff'};
  border: 2px solid #108ee9;
`;

const AuthCodeBtn = ({ interval = 60, callback }) => {
  const [count, setCount] = useState(interval);
  const [delay, setDelay] = useState(null);

  useInterval(() => {
    if (count > 0) {
      setCount(count - 1);
    } else {
      setCount(interval);
      setDelay(null);
    }
  }, delay);

  function handleClick() {
    if (count !== interval) return;
    if (!callback()) return;

    setCount(59);
    setDelay(1000);
  }

  return (
    <Button onClick={handleClick} isInitial={count === interval}>
      {count === interval ? "发送验证码" : `${count} 秒`}
    </Button>
  );
};

export default AuthCodeBtn;
