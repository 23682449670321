import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { createForm } from 'rc-form';
import { withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Icon, Button, Toast } from 'antd-mobile';

import * as actionCreators from '../store/actionCreators';
// import IconFont from '../../../components/IconFont';
import AuthCodeBtn from '../../../components/AuthCodeBtn';

import { Container } from './style';

const toastMsgObj = {
  phone: '手机号',
  captcha: '验证码',
};

const handleErrorsMsg = errors => Object.keys(errors).map(key => toastMsgObj[key]);

const Login = props => {
  const {
    form,
    loginStatus,
    toastMessage,
    isToastVisible,
    sentVcodeDispatch,
    LoginByVcodeDispatch,
    toggleTaostVisibleDispatch,
  } = props;
  const { getFieldProps, validateFields, getFieldValue } = form;
  const [showStatus, setShowStatus] = useState(true);

  useEffect(() => {
    if (isToastVisible) {
      Toast.fail(toastMessage, 2, () => toggleTaostVisibleDispatch());
    }
  }, [isToastVisible, toastMessage, toggleTaostVisibleDispatch]);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');
    console.log('@@userToken', userToken);
    if (loginStatus || (!!userToken && userToken.token)) {
      props.history.push('/home');
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const handleBack = useCallback(() => {
    setShowStatus(false);
  }, []);

  const handleSendVcode = useCallback(() => {
    const phone = getFieldValue('phone');
    if (phone && phone.length === 11) {
      sentVcodeDispatch(phone);
      Toast.success('已发送验证码', 2);
      return true;
    } else {
      Toast.fail('请输入正确的手机号', 2);
      return false;
    }
    // eslint-disable-next-line
  }, [getFieldValue]);

  const handleLogin = () => {
    validateFields((errors, values) => {
      if (errors) {
        return Toast.fail(handleErrorsMsg(errors).join(',') + ' 有误', 2);
      }
      LoginByVcodeDispatch(values.phone, values.captcha);
    });
  };

  return (
    <CSSTransition
      in={showStatus}
      timeout={350}
      classNames="fly"
      appear={true}
      unmountOnExit
      onExited={props.history.goBack}
    >
      <Container>
        <div className="header">
          <Icon onClick={handleBack} className="icon-close" size="lg" type="cross" />登 录
        </div>

        <div className="input-container">
          <input
            type="number"
            pattern="\d*"
            placeholder="请输入手机号"
            {...getFieldProps('phone', { rules: [{ required: true, len: 11 }] })}
          />
          <div className="inner-box">
            <input
              className="input-password"
              type="number"
              pattern="\d*"
              placeholder="请输入验证码"
              {...getFieldProps('captcha', { rules: [{ required: true }] })}
            />
            <AuthCodeBtn callback={handleSendVcode} />
          </div>
          <p className="tips">温馨提示：若登录遇到问题请致电 0871-63156113</p>
        </div>

        <Button onClick={handleLogin} style={{ marginTop: 40 }} type="primary">
          登录
        </Button>

        {/* <div className="footer">
          <div className="title">
            <span className="line"></span>
            <span className="desc">第三方登录</span>
            <span className="line"></span>
          </div>
          <IconFont fontSize={40} color="#1AAD19" value="&#xe66a;" />
        </div> */}
      </Container>
    </CSSTransition>
  );
};

const mapStateToProps = state => ({
  sentStatus: state.getIn(['user', 'sentStatus']),
  loginStatus: state.getIn(['user', 'loginStatus']),
  toastMessage: state.getIn(['user', 'toastMessage']),
  isToastVisible: state.getIn(['user', 'isToastVisible']),
});

const mapDispatchToProps = dispatch => {
  return {
    LoginByVcodeDispatch(phone, vcode) {
      dispatch(actionCreators.loginByVcode(phone, vcode));
    },
    sentVcodeDispatch(phone) {
      dispatch(actionCreators.sentVcode(phone));
    },
    changeSentStatusDispatch() {
      dispatch(actionCreators.saveSentStatus(false));
    },
    toggleTaostVisibleDispatch() {
      dispatch(actionCreators.toggleIsToastVisible());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(withRouter(createForm()(Login))));
